import { stats } from "../utils/constants";
import styles from "../style";
import {key} from "../assets";

const Stats = ({statistics}) => (
  <section className={`${styles.flexCenter} flex-col flex-wrap sm:mb-20 mb-6`}>
      <div className={`flex-1 flex justify-center items-center flex-col m-3`} >
          <p className="font-poppins font-normal xs:text-[20.45px] text-[18px] xs:leading-[26.58px] leading-[21.58px] text-gradient uppercase ml-3">
              Your Address :&nbsp;
          </p>
          <h4 className="font-poppins font-semibold xs:text-[40.89px] text-[12px] xs:leading-[53.16px] leading-[43.16px] text-white">
              {statistics.address}
          </h4>
      </div>
      <div className={`flex-1 flex justify-center items-center flex-col m-3`} >
          <p className="font-poppins font-normal xs:text-[20.45px] text-[18px] xs:leading-[26.58px] leading-[21.58px] text-gradient uppercase ml-3">
              Parent Address :&nbsp;
          </p>
          <h4 className="font-poppins font-semibold xs:text-[40.89px] text-[12px] xs:leading-[53.16px] leading-[43.16px] text-white">
              {statistics.parent}
          </h4>
      </div>
      <div className={`flex-1 flex justify-center items-center flex-col m-3`} >
          <p className="font-poppins font-normal xs:text-[20.45px] text-[18px] xs:leading-[26.58px] leading-[21.58px] text-gradient uppercase ml-3">
              Activeness :&nbsp;
          </p>
          <h4 className="font-poppins font-semibold xs:text-[40.89px] text-[18px] xs:leading-[53.16px] leading-[43.16px] text-white">
              {statistics.activeness}
          </h4>
      </div>
      <div className={`flex-1 flex justify-center items-center flex-col m-3`} >
          <p className="font-poppins font-normal xs:text-[20.45px] text-[18px] xs:leading-[26.58px] leading-[21.58px] text-gradient uppercase ml-3">
              Child Number :&nbsp;
          </p>
          <h4 className="font-poppins font-semibold xs:text-[40.89px] text-[18px] xs:leading-[53.16px] leading-[43.16px] text-white">
              {statistics.childNo}
          </h4>
      </div>
      <div className={`flex-1 flex justify-center items-center flex-col m-3`} >
          <p className="font-poppins font-normal xs:text-[20.45px] text-[18px] xs:leading-[26.58px] leading-[21.58px] text-gradient uppercase ml-3">
              Amount :&nbsp;
          </p>
          <h4 className="font-poppins font-semibold xs:text-[40.89px] text-[18px] xs:leading-[53.16px] leading-[43.16px] text-white">
              {statistics.amount}
          </h4>
      </div>
      <div className={`flex-1 flex justify-center items-center flex-col m-3`} >
          {statistics.activeness === 'true' ? (
              <a href="https://t.me/Finnovel_bot" className={`d-flex text-center flex-col py-4 px-6 font-poppins font-medium text-[14px] text-primary bg-blue-gradient rounded-[10px] outline-none ${styles}`}>
                  <p>Open The Doors Of Financial World</p>
              </a>
          ) : null}
      </div>
  </section>
);

export default Stats;
