import React, { useState, useEffect } from "react";
import Web3 from "web3";
import contractAbi from "./utils/ABI";
import "./App.css";
import {Navbar, Hero, Billing, Business, CardDeal, Clients, CTA, Footer, Stats, Testimonials} from "./components"
import styles from "./style";



function App() {
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [contractAddress] = useState('0xF4bAc14D580C22282552e49cE27371b03A727527');
  const [owner, setOwner] = useState('waiting...');
  const [connectedAccount, setConnectedAccount] = useState(null); // Added state for connected account
  const [statistics, setStatistics] = useState({
    address : 'loading',
    parent: 'loading',
    activeness: 'loading',
    childNo: 'loading',
    amount: 'loading'
  });

  const initWeb3 = async () => {
    try {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);

      const contractInstance = new web3Instance.eth.Contract(
          contractAbi,
          contractAddress
      );
      setContract(contractInstance);

      const accounts = await web3Instance.eth.getAccounts();
      setConnectedAccount(accounts[0]);

      const GetUserInfo = await contractInstance.methods.getUserByAddress(connectedAccount).call();
      console.log(GetUserInfo)
      console.log(connectedAccount)
      setStatistics(prev => ({
        ...prev,
        address: GetUserInfo.Address.toString(),
        parent: GetUserInfo.parent.toString(),
        activeness: GetUserInfo.active.toString(),
        childNo: GetUserInfo.childNo.toString(),
        amount: GetUserInfo.amount.toString()
      }));
    } catch (error) {
      console.error("Error initializing Web3 :", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (contract) {
        try {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
          const contractInstance = new web3Instance.eth.Contract(
              contractAbi,
              contractAddress
          );
          setContract(contractInstance);
          const accounts = await web3Instance.eth.getAccounts();
          setConnectedAccount(accounts[0]);
          const GetUserInfo = await contract.methods.getUserByAddress(connectedAccount).call();
          console.log(GetUserInfo)
          setStatistics(prev => ({
            ...prev,
            address: GetUserInfo.Address.toString(),
            parent: GetUserInfo.parent.toString(),
            activeness: GetUserInfo.active.toString(),
            childNo: GetUserInfo.childNo.toString(),
            amount: GetUserInfo.amount.toString()
          }));
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      }
    };

    const intervalId = setInterval(fetchData, 10000);

    // Return a cleanup function
    return () => clearInterval(intervalId);
  }, [contract]);

  useEffect(() => {
    const initializeWeb3 = async () => {
      try {
        await initWeb3();
        const account = await window.ethereum.request({ method: "eth_requestAccounts" });
        /* second way to get accounts */
        /*const accounts = await window.ethereum.eth.getAccounts();*/
        setConnectedAccount(account[0])
      } catch (error) {
        console.error("Error connecting wallet:", error);
      }
    };

    initializeWeb3();
  }, []);

 

  

  return (
    <div className="bg-primary w-full overflow-hidden">
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar initWeb3={initWeb3} connectedAccount={connectedAccount}/>
        </div>
      </div>
      <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Hero/>
        </div>
      </div>
      <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <CTA web3={web3} contract={contract}/>
          <Stats statistics={statistics}/>
          <Business/>
          <Footer/>
        </div>
      </div> 
    </div>
)
}

export default App;
