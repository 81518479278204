import styles from "../style";
import { useState } from "react";

const CTA = ({ web3, contract }) => {
    const [parent, setParent] = useState('');

    const participate = async () => {
        try {
            const accounts = await web3.eth.getAccounts();
            const valueToSend = web3.utils.toWei("55", "ether");

            await contract.methods.participate(parent).send({
                from: accounts[0],
                value: valueToSend,
            });

            console.log("Participate successful!");
        } catch (error) {
            console.error("Error participating:", error);
        }
    };

    return (
        <section className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
            <div className="flex flex-col items-center w-full">
                <input
                    type="text"
                    onChange={(e) => setParent(e.target.value)}
                    placeholder={`Loan Address`}
                    className={`w-64 md:w-3/4 py-4 mt-5 px-6 font-poppins font-medium text-[18px] text-primary bg-blue-gradient rounded-[10px] outline-none text-center`}
                />
                <button
                    onClick={participate} // Corrected the onClick function
                    className={`w-32 md:w-1/6 p-[10px] mt-5 font-poppins font-medium text-[18px] text-primary bg-blue-gradient rounded-[10px] outline-none`}
                >
                    Send
                </button>
            </div>
        </section>
    );
};

export default CTA;
