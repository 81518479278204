import React from 'react'
import { useState } from 'react'
import {logo, wallet} from "../assets"

const ConnectWallet = async () => {
    await window.ethereum.request({ method: "eth_requestAccounts" });
}

const Navbar = ({initWeb3, connectedAccount }) => {
    const [toggle, setToggle] = useState(false)
  return (
    <nav className='w-full py-6 flex justify-between items-center navbar'>
        <img src={logo} className={`h-[100px]`} alt="logo"/>
        <a onClick={ConnectWallet} className='hidden cursor-pointer sm:flex font-poppins text-[16px] text-black bg-blue-gradient pr-4 rounded  flex flex-row'>
            <img src={wallet} alt="wallet"  className='w-[48px] h-[48px] p-1'/>
            <span className='items-center justify-center flex'>{connectedAccount ? connectedAccount : "Connect Wallet"}</span>
        </a>
    </nav>
  )
}

export default Navbar