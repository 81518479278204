import styles from "../style";
import { logo } from "../assets";
import { footerLinks, socialMedia } from "../utils/constants";

const Footer = () => (
  <section className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
    <div className={`${styles.flexStart}  flex-col mb-8 w-full items-center`}>
      <div className="items-center flex flex-col justify-center">
        <img
          src={logo}
          alt="hoobank"
          className="w-[145px] object-contain"
        />
        <p className={`${styles.paragraph} mt-4 justify-center`}>
          *Blockchain Based Lending Platform*
        </p>
      </div>
    </div>

    <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
      <p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-white">
        Copyright Ⓒ 2023 Powered By Finnovel.io. All Rights Reserved.
      </p>

      <div className="flex flex-row md:mt-0 mt-6">
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-[21px] h-[21px] object-contain cursor-pointer ${
              index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
            }`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </div>
    </div>
  </section>
);

export default Footer;
