import { features } from "../utils/constants";
import styles, { layout } from "../style";
import Button from "./Button";

const FeatureCard = ({ icon, title, content, index }) => (
  <div className={`flex flex-row p-6 rounded-[20px] ${index !== features.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} text-gradient`}>
      <img src={icon} alt="star" className="w-[50%] h-[50%] object-contain"/>
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4>
      <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

const Business = () =>  (
  <section id="features" className={`${layout.section} pb-0`}>
    <div className={layout.sectionInfo}>
        <h1 className="flex-1 font-poppins font-semibold ss:text-[42px] text-[52px] text-white ss:leading-[100.8px] leading-[75px]">
            <br className="sm:block hidden" />{" "}
            <span className="text-gradient ss:text-[76px]">Double your Money in A Few Seconds.</span>{" "}
        </h1>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
      "Embrace a revolutionary lending experience with our blockchain-based platform. Enjoy unparalleled transparency and peace of mind as we redefine financial transactions, prioritizing security and trust in every interaction."
      </p>

      <a href="https://polygonscan.com/address/0xf4bac14d580c22282552e49ce27371b03a727527" className={`mt-4 py-4 px-6 font-poppins font-medium text-[18px] text-primary bg-blue-gradient rounded-[10px] outline-none ${styles}`}>
        Check Blockchain Explorer
      </a>
    </div>

    <div className={`${layout.sectionImg} flex-col`}>
      {features.map((feature, index) => (
        <FeatureCard key={feature.id} {...feature} index={index} />
      ))}
    </div>
  </section>
);

export default Business;
